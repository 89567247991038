function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

let tagsToReplace = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;'
};

function replaceTag(tag) {
    return tagsToReplace[tag] || tag;
}

function safeTagsReplace(str) {
    return str.replace(/[&<>]/g, replaceTag);
}

$('#payment-purpose').change(function () {
    let selectedPaymentPurposeIndex = $('#payment-purpose').find(":selected").val();
    let paymentParts = paymentPurposes[selectedPaymentPurposeIndex]['parts'];
    if (paymentParts.length > 0) {
        $('.payment-part-container').removeClass('d-none');
        let newPaymentPartsContent = '';
        paymentParts.forEach((paymentPart, indexPaymentPart) => {
            newPaymentPartsContent += `
                <div class="form-check ms-4 ms-lg-4 d-flex">
                    <input class="form-check-input" type="checkbox" value="${paymentPart}" id="payment-part-${indexPaymentPart}" checked name="payment-part">
                    <label class="form-check-label my-1 ms-3" for="payment-part-${indexPaymentPart}">
                        ${ safeTagsReplace(paymentPart) }
                    </label>
                </div>
            `;
        });
        $('.payment-part-wrapper').html(newPaymentPartsContent);
    } else {
        $('.payment-part-container').addClass('d-none');
        $('.payment-part-wrapper').html('');
    }
});

$('#payment-amount, #payer-phone').keypress(function (event) {
    let acceptKeyCodes = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
    if (!acceptKeyCodes.includes(event.keyCode)) {
        event.preventDefault();
        return false;
    }
});

$('#payment-amount').keyup(function () {
    let currentAmount = $('#payment-amount').val().replaceAll(',', '');
    if (currentAmount) {
        let currentAmountInt = parseInt(currentAmount);
        $('#payment-amount').val(numberWithCommas(currentAmountInt));
    } else {
        $('#payment-amount').val('');
    }
});

$.validator.addMethod("lettersOnly", function(value, element)
{
    return this.optional(element) || /^[a-z đúùủũụưứừửữựéèẻẽẹêếềểễệóòỏõọôốồổỗộơớờởỡợáàảãạâấầẩẫậăắằẳẵặíìỉĩịýỳỷỹỵ]+$/i.test(value);
}, "Vui lòng nhập tên học sinh bằng ký tự a-z và dấu cách");

$.validator.addMethod("lettersAndDigits", function(value, element)
{
    return this.optional(element) || /^[a-z0-9 đúùủũụưứừửữựéèẻẽẹêếềểễệóòỏõọôốồổỗộơớờởỡợáàảãạâấầẩẫậăắằẳẵặíìỉĩịýỳỷỹỵ]+$/i.test(value);
}, "Vui lòng chỉ nhập chữ cái và chữ số");

$.validator.addMethod("emailRfc2822", function(value, element)
{
    return this.optional(element) || /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])/.test(value);
}, "Vui lòng nhập đúng định dạng email");

$('document').ready(function() {
    $('#payment-purpose').trigger('change');
    $($('input[name=payment-method]')[0]).attr('checked', true);

    $("#payment-form").validate({
        onfocusout: false,
        onkeyup: false,
        onclick: false,
        rules: {
            "payment-purpose": {
                required: true,
            },
            "full-name": {
                required: true,
                lettersOnly: true,
                maxlength : 40,
            },
            "student-code": {
                required: true,
                maxlength : 20,
                lettersAndDigits: true,
            },
            "department": {
                required: true,
            },
            "education-program": {
                required: true,
            },
            "grade": {
                required: true,
            },
            "payer-name": {
                required: true,
                maxlength : 40,
                lettersOnly: true,
            },
            "payer-phone": {
                required: true,
                maxlength : 12,
                minlength : 7,
                digits: true,
            },
            "payer-relationship": {
                required: true,
            },
            "email": {
                emailRfc2822: true,
            },
            "payment-amount": {
                required: true,
                digits: true,
                min: 10000,
                max: 200000000,
            },
            "payment-description": {
                maxlength : 255,
            },
            "payment-part": {
                required: true,
            },
            "payment-method": {
                required: true,
            },
            "policy": {
                required: true,
            }
        },
        messages: {
            "payment-purpose": {
                required: "Không được để trống thông tin này",
            },
            "full-name": {
                required: "Không được để trống thông tin này",
                lettersOnly: "Vui lòng nhập tên học sinh bằng ký tự a-z và dấu cách",
                maxlength: "Vui lòng nhập tối đa 40 kí tự",
            },
            "student-code": {
                required: "Không được để trống thông tin này",
                maxlength: "Vui lòng nhập tối đa 20 kí tự",
                lettersAndDigits: "Vui lòng chỉ nhập chữ cái và chữ số",
            },
            "department": {
                required: "Không được để trống thông tin này",
            },
            "education-program": {
                required: "Không được để trống thông tin này",
            },
            "grade": {
                required: "Không được để trống thông tin này",
            },
            "payer-name": {
                required: "Không được để trống thông tin này",
                maxlength: "Vui lòng nhập tối đa 40 kí tự",
                lettersOnly: "Vui lòng chỉ nhập chữ cái",
            },
            "payer-phone": {
                required: "Không được để trống thông tin này",
                maxlength: "Vui lòng nhập tối đa 12 kí tự",
                minlength: "Vui lòng nhập tối thiểu 7 kí tự",
                digits: "Vui lòng chỉ nhập số",
            },
            "payer-relationship": {
                required: "Không được để trống thông tin này",
            },
            "email": {
                emailRfc2822: "Vui lòng nhập đúng định dạng email",
            },
            "payment-amount": {
                required: "Không được để trống thông tin này",
                digits: "Vui lòng nhập số tiền thanh toán tối thiểu 10,000 VNĐ hoặc tối đa 200,000,000 VNĐ",
                min: "Vui lòng nhập số tiền thanh toán tối thiểu 10,000 VNĐ",
                max: "Vui lòng nhập số tiền thanh toán tối đa 200,000,000 VNĐ",
            },
            "payment-description": {
                maxlength: "Vui lòng nhập tối đa 255 kí tự",
            },
            "payment-part": {
                required: "Không được để trống thông tin này",
            },
            "payment-method": {
                required: "Không được để trống thông tin này",
            },
            "policy": {
                required: "Vui lòng đồng ý với Điều khoản",
            }
        },
        errorPlacement: function (error, element) {
            let name = $(element).attr("name");
            error.appendTo($(".error-" + name));
        },
    });
});

$('#checkout-button').click(function () {
    let amountInt = parseInt($('#payment-amount').val().replaceAll(',', ''));
    if (amountInt) {
        $('#payment-amount').val(amountInt);
    }
    let paymentValid = $("#payment-form").valid();

    let errorElements = $('label.error');
    let scrollPosition = 0;
    $(errorElements).each((index, element) => {
        if ($(element).text()) {
            scrollPosition = $(element).offset().top;
            return false;
        }
    }, scrollPosition);
    if (!paymentValid) {
        if (amountInt) {
            $('#payment-amount').val(numberWithCommas(amountInt));
        } else {
            $('#payment-amount').val();
        }
        $('html').animate({
            scrollTop: scrollPosition - 100
        }, 200);
        return false;
    }

    let paymentAmount = parseInt($('#payment-amount').val().replaceAll(',', ''));
    let paymentDescription = $('#payment-description').val();
    let paymentMethod = $("input[name='payment-method']:checked").val();
    let paymentPurpose = $('#payment-purpose option:checked').text();
    let fullName = $('#full-name').val();
    let studentCode = $('#student-code').val();
    let department = $('#department option:checked').text();
    let educationProgram = $('#education-program option:checked').text();
    let grade = $('#student-grade option:checked').text();
    let payerName = $('#payer-name').val();
    let payerPhone = $('#payer-phone').val();
    let payerRelationShip = $('#payer-relationship option:checked').text();
    let email = $('#email').val();
    let paymentPartElements = $('input[name=payment-part]:checkbox:checked');
    let paymentParts = [];
    $(paymentPartElements).each((index, paymentPartElement) => {
        paymentParts.push($(paymentPartElement).val());
    }, paymentParts);
    paymentParts = paymentParts.join('; ');

    let urlOrigin = window.location.origin;
    let checkPaymentPath = '/api/'

    axios.get(urlOrigin + checkPaymentPath + merchantId)
        .then((response) => {
            let responseData = response.data;
            if (responseData.status_code === 200) {
                let merchantPaymentMethods = responseData.data;
                if (!merchantPaymentMethods.includes('ATM_CARD') && !merchantPaymentMethods.includes('CREDIT_CARD')) {
                    window.location = urlOrigin;
                    return false;
                }
                if (paymentMethod === 'ATM_CARD') {
                    if (!merchantPaymentMethods.includes('ATM_CARD')) {
                        if (merchantPaymentMethods.includes('CREDIT_CARD')) {
                            $('.payment-modal-btn').trigger('click');
                            return false;
                        } else {
                            window.location = urlOrigin;
                            return false;
                        }
                    }
                }
                if (paymentMethod === 'CREDIT_CARD') {
                    if (!merchantPaymentMethods.includes('CREDIT_CARD')) {
                        if (merchantPaymentMethods.includes('ATM_CARD')) {
                            $('.payment-modal-btn').trigger('click');
                            return false;
                        } else {
                            window.location = urlOrigin;
                            return false;
                        }
                    }
                }
                let urlPathName = window.location.pathname;
                let checkoutPath = '/checkout';
                let requestData = `
                    <input type="hidden" name="payment_amount" value="${paymentAmount}" />
                    <input type="hidden" name="payment_description" value="${paymentDescription}" />
                    <input type="hidden" name="payment_method" value="${paymentMethod}" />
                    <input type="hidden" name="payment_purpose" value="${paymentPurpose}" />
                    <input type="hidden" name="full_name" value="${fullName}" />
                    <input type="hidden" name="student_code" value="${studentCode}" />
                    <input type="hidden" name="department" value="${department}" />
                    <input type="hidden" name="education_program" value="${educationProgram}" />
                    <input type="hidden" name="grade" value="${grade}" />
                    <input type="hidden" name="payer_name" value="${payerName}" />
                    <input type="hidden" name="payer_phone" value="${payerPhone}" />
                    <input type="hidden" name="payer_relationship" value="${payerRelationShip}" />
                    <input type="hidden" name="email" value="${email}" />
                    <input type="hidden" name="payment_parts" value="${paymentParts}" />
                `;
                $('#payment-form').append(requestData).attr('action', urlOrigin + urlPathName + checkoutPath).submit();
                return false;
            } else {
                window.location = urlOrigin;
                return false;
            }
        })
        .catch(() => {
            window.location = urlOrigin;
            return false;
        });
});

$('#payment-purpose').change(function () {
    let paymentPurposeId = parseInt($('#payment-purpose').find(":selected").val());
    const ADMISSION = 99;
    if (paymentPurposeId === ADMISSION) {
        $('#student-grade').toggleClass('d-none');
        $('#student-education-program').toggleClass('d-none');

        $("#payment-form").validate({
            onfocusout: false,
            onkeyup: false,
            onclick: false,
            rules: {
                "payment-purpose": {
                    required: true,
                },
                "full-name": {
                    required: true,
                    lettersOnly: true,
                    maxlength : 40,
                },
                "student-code": {
                    required: true,
                    maxlength : 20,
                    lettersAndDigits: true,
                },
                "department": {
                    required: true,
                },
                "education-program": {
                    required: true,
                },
                "payer-name": {
                    required: true,
                    maxlength : 40,
                    lettersOnly: true,
                },
                "payer-phone": {
                    required: true,
                    maxlength : 12,
                    minlength : 7,
                    digits: true,
                },
                "payer-relationship": {
                    required: true,
                },
                "email": {
                    emailRfc2822: true,
                },
                "payment-amount": {
                    required: true,
                    digits: true,
                    min: 10000,
                    max: 200000000,
                },
                "payment-description": {
                    maxlength : 255,
                },
                "payment-part": {
                    required: true,
                },
                "payment-method": {
                    required: true,
                },
                "policy": {
                    required: true,
                }
            },
            messages: {
                "payment-purpose": {
                    required: "Không được để trống thông tin này",
                },
                "full-name": {
                    required: "Không được để trống thông tin này",
                    lettersOnly: "Vui lòng nhập tên học sinh bằng ký tự a-z và dấu cách",
                    maxlength: "Vui lòng nhập tối đa 40 kí tự",
                },
                "student-code": {
                    required: "Không được để trống thông tin này",
                    maxlength: "Vui lòng nhập tối đa 20 kí tự",
                    lettersAndDigits: "Vui lòng chỉ nhập chữ cái và chữ số",
                },
                "department": {
                    required: "Không được để trống thông tin này",
                },
                "education-program": {
                    required: "Không được để trống thông tin này",
                },
                "grade": {
                    required: "Không được để trống thông tin này",
                },
                "payer-name": {
                    required: "Không được để trống thông tin này",
                    maxlength: "Vui lòng nhập tối đa 40 kí tự",
                    lettersOnly: "Vui lòng chỉ nhập chữ cái",
                },
                "payer-phone": {
                    required: "Không được để trống thông tin này",
                    maxlength: "Vui lòng nhập tối đa 12 kí tự",
                    minlength: "Vui lòng nhập tối thiểu 7 kí tự",
                    digits: "Vui lòng chỉ nhập số",
                },
                "payer-relationship": {
                    required: "Không được để trống thông tin này",
                },
                "email": {
                    emailRfc2822: "Vui lòng nhập đúng định dạng email",
                },
                "payment-amount": {
                    required: "Không được để trống thông tin này",
                    digits: "Vui lòng nhập số tiền thanh toán tối thiểu 10,000 VNĐ hoặc tối đa 200,000,000 VNĐ",
                    min: "Vui lòng nhập số tiền thanh toán tối thiểu 10,000 VNĐ",
                    max: "Vui lòng nhập số tiền thanh toán tối đa 200,000,000 VNĐ",
                },
                "payment-description": {
                    maxlength: "Vui lòng nhập tối đa 255 kí tự",
                },
                "payment-part": {
                    required: "Không được để trống thông tin này",
                },
                "payment-method": {
                    required: "Không được để trống thông tin này",
                },
                "policy": {
                    required: "Vui lòng đồng ý với Điều khoản",
                }
            },
            errorPlacement: function (error, element) {
                let name = $(element).attr("name");
                error.appendTo($(".error-" + name));
            },
        });
    } else {
        $('#student-grade').removeClass('d-none');
        $('#student-education-program').removeClass('d-none');

        $('#student-education-program').addClass('d-none');

        $("#payment-form").validate({
            onfocusout: false,
            onkeyup: false,
            onclick: false,
            rules: {
                "payment-purpose": {
                    required: true,
                },
                "full-name": {
                    required: true,
                    lettersOnly: true,
                    maxlength : 40,
                },
                "student-code": {
                    required: true,
                    maxlength : 20,
                    lettersAndDigits: true,
                },
                "department": {
                    required: true,
                },
                "grade": {
                    required: true,
                },
                "payer-name": {
                    required: true,
                    maxlength : 40,
                    lettersOnly: true,
                },
                "payer-phone": {
                    required: true,
                    maxlength : 12,
                    minlength : 7,
                    digits: true,
                },
                "payer-relationship": {
                    required: true,
                },
                "email": {
                    emailRfc2822: true,
                },
                "payment-amount": {
                    required: true,
                    digits: true,
                    min: 10000,
                    max: 200000000,
                },
                "payment-description": {
                    maxlength : 255,
                },
                "payment-part": {
                    required: true,
                },
                "payment-method": {
                    required: true,
                },
                "policy": {
                    required: true,
                }
            },
            messages: {
                "payment-purpose": {
                    required: "Không được để trống thông tin này",
                },
                "full-name": {
                    required: "Không được để trống thông tin này",
                    lettersOnly: "Vui lòng nhập tên học sinh bằng ký tự a-z và dấu cách",
                    maxlength: "Vui lòng nhập tối đa 40 kí tự",
                },
                "student-code": {
                    required: "Không được để trống thông tin này",
                    maxlength: "Vui lòng nhập tối đa 20 kí tự",
                    lettersAndDigits: "Vui lòng chỉ nhập chữ cái và chữ số",
                },
                "department": {
                    required: "Không được để trống thông tin này",
                },
                "education-program": {
                    required: "Không được để trống thông tin này",
                },
                "grade": {
                    required: "Không được để trống thông tin này",
                },
                "payer-name": {
                    required: "Không được để trống thông tin này",
                    maxlength: "Vui lòng nhập tối đa 40 kí tự",
                    lettersOnly: "Vui lòng chỉ nhập chữ cái",
                },
                "payer-phone": {
                    required: "Không được để trống thông tin này",
                    maxlength: "Vui lòng nhập tối đa 12 kí tự",
                    minlength: "Vui lòng nhập tối thiểu 7 kí tự",
                    digits: "Vui lòng chỉ nhập số",
                },
                "payer-relationship": {
                    required: "Không được để trống thông tin này",
                },
                "email": {
                    emailRfc2822: "Vui lòng nhập đúng định dạng email",
                },
                "payment-amount": {
                    required: "Không được để trống thông tin này",
                    digits: "Vui lòng nhập số tiền thanh toán tối thiểu 10,000 VNĐ hoặc tối đa 200,000,000 VNĐ",
                    min: "Vui lòng nhập số tiền thanh toán tối thiểu 10,000 VNĐ",
                    max: "Vui lòng nhập số tiền thanh toán tối đa 200,000,000 VNĐ",
                },
                "payment-description": {
                    maxlength: "Vui lòng nhập tối đa 255 kí tự",
                },
                "payment-part": {
                    required: "Không được để trống thông tin này",
                },
                "payment-method": {
                    required: "Không được để trống thông tin này",
                },
                "policy": {
                    required: "Vui lòng đồng ý với Điều khoản",
                }
            },
            errorPlacement: function (error, element) {
                let name = $(element).attr("name");
                error.appendTo($(".error-" + name));
            },
        });
    }
});
